import { images } from "../images";

export const batStatsData = [
  {
    id: 1,
    tabName: "Most Runs",
    battingStats: [
      {
        id: 1,
        img: images.stats01.image,
        alt: images.stats01.alt,
        name: "KETAN MHATRE",
        mplayed: "6",
        tRuns: "148",
      },
      {
        id: 2,
        img: images.stats02.image,
        alt: images.stats02.alt,
        name: "BABLU PATIL",
        mplayed: "6",
        tRuns: "109",
      },
      {
        id: 3,
        img: images.stats03.image,
        alt: images.stats03.alt,
        name: "SAGAR ALI",
        mplayed: "6",
        tRuns: "102",
      },
      {
        id: 4,
        img: images.stats04.image,
        alt: images.stats04.alt,
        name: "SANJAY KANOJJIYA",
        mplayed: "6",
        tRuns: "91",
      },
      {
        id: 5,
        img: images.stats05.image,
        alt: images.stats05.alt,
        name: "DILIP BINJWA",
        mplayed: "6",
        tRuns: "43",
      },
    ],
  },
  // {
  //   id: 2,
  //   tabName: "Highest Strike Rate",
  //   battingStats: [
  //     {
  //       id: 1,
  //       img: images.stats02.image,
  //       alt: images.stats02.alt,
  //       name: "BABLU PATIL",
  //       mplayed: "6",
  //       tRuns: "242.22",
  //     },
  //     {
  //       id: 2,
  //       img: images.stats10.image,
  //       alt: images.stats10.alt,
  //       name: "V. Vignesh",
  //       mplayed: "3",
  //       tRuns: "200",
  //     },
  //     {
  //       id: 3,
  //       img: images.stats01.image,
  //       alt: images.stats01.alt,
  //       name: "KETAN MHATRE",
  //       mplayed: "6",
  //       tRuns: "187.34",
  //     },
  //     {
  //       id: 4,
  //       img: images.stats04.image,
  //       alt: images.stats04.alt,
  //       name: "Sanjay Kanojjiya",
  //       mplayed: "6",
  //       tRuns: "165.45",
  //     },
  //     {
  //       id: 5,
  //       img: images.stats03.image,
  //       alt: images.stats03.alt,
  //       name: "Sagar Ali",
  //       mplayed: "6",
  //       tRuns: "152.24",
  //     },
  //   ],
  // },
];

export const batStatsData2 = [
  {
    id: 1,
    tabName: "Most Runs",
    battingStats: [
      {
        id: 1,
        img: images.season02Stats01.image,
        alt: images.season02Stats01.alt,
        name: "KETAN MHATRE",
        mplayed: "10",
        tRuns: "236",
      },
      {
        id: 2,
        img: images.season02Stats02.image,
        alt: images.season02Stats02.alt,
        name: "JAGAT SARKAR",
        mplayed: "10",
        tRuns: "226",
      },
      {
        id: 3,
        img: images.season02Stats03.image,
        alt: images.season02Stats03.alt,
        name: "SUMEET DHEKALE",
        mplayed: "10",
        tRuns: "185",
      },
    ],
  },
];

export const ballStatsData = [
  {
    id: 1,
    tabName: "Wicket Takers",
    bowlingStats: [
      {
        id: 1,
        img: images.stats05.image,
        alt: images.stats05.alt,
        name: "Dilip Binjwa",
        mplayed: "6",
        tWickets: "11",
      },
      {
        id: 2,
        img: images.stats06.image,
        alt: images.stats06.alt,
        name: "Vishwanath Jadhav",
        mplayed: "6",
        tWickets: "8",
      },
      {
        id: 3,
        img: images.stats07.image,
        alt: images.stats07.alt,
        name: "R. Thavith Kumar",
        mplayed: "3",
        tWickets: "6",
      },
      {
        id: 4,
        img: images.stats08.image,
        alt: images.stats08.alt,
        name: "Farman Khan",
        mplayed: "6",
        tWickets: "5",
      },
      {
        id: 5,
        img: images.stats09.image,
        alt: images.stats09.alt,
        name: "Rajdeep Jadeja",
        mplayed: "3",
        tWickets: "4",
      },
    ],
  },
  // {
  //   id: 2,
  //   tabName: "Best Bowling Figures",
  //   bowlingStats: [
  //     {
  //       id: 1,
  //       img: images.stats07.image,
  //       alt: images.stats07.alt,
  //       name: "R. Thavith Kumar",
  //       mplayed: "3",
  //       tWickets: "4/13",
  //     },
  //     {
  //       id: 2,
  //       img: images.stats05.image,
  //       alt: images.stats05.alt,
  //       name: "Dilip Binjwa",
  //       mplayed: "6",
  //       tWickets: "4/16",
  //     },
  //     {
  //       id: 3,
  //       img: images.stats09.image,
  //       alt: images.stats09.alt,
  //       name: "Rajdeep Jadeja",
  //       mplayed: "3",
  //       tWickets: "3/19",
  //     },
  //     {
  //       id: 4,
  //       img: images.stats10.image,
  //       alt: images.stats10.alt,
  //       name: "V. Vignesh",
  //       mplayed: "3",
  //       tWickets: "2/6",
  //     },
  //     {
  //       id: 5,
  //       img: images.stats06.image,
  //       alt: images.stats06.alt,
  //       name: "Vishwanath Jadhav",
  //       mplayed: "6",
  //       tWickets: "2/9",
  //     },
  //   ],
  // },
];

export const ballStatsData2 = [
  {
    id: 1,
    tabName: "Wicket Takers",
    bowlingStats: [
      {
        id: 1,
        img: images.season02Stats04.image,
        alt: images.season02Stats04.alt,
        name: "JIGNESH PATEL",
        mplayed: "10",
        tWickets: "10",
      },
      {
        id: 2,
        img: images.season02Stats05.image,
        alt: images.season02Stats05.alt,
        name: "MOHAMMAD ZEESHAN",
        mplayed: "9",
        tWickets: "9",
      },
      {
        id: 3,
        img: images.season02Stats06.image,
        alt: images.season02Stats06.alt,
        name: "ANURAG SARSHAR",
        mplayed: "10",
        tWickets: "7",
      },
    ],
  },
];
