import React, { useEffect, useRef, useState } from "react";
import "./fixture.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  dividerBat,
  fixtureBanner,
  homeIcon,
  vsImg,
  yellowTexture,
} from "../../images";
import { ongoinData, upcomingData } from "../../data/fixtureData";
import { Link } from "react-router-dom";
import { homepageURL } from "../../components/helpers/constant-words";
import WhatsApp from "../../components/whatsApp/whatsApp";
import ContactUs from "../../components/ContactUs/ContactUs";

const Fixture = () => {
  // const imgBoxRefs = useRef([]);
  // const [isHeight, setIsHeight] = useState([]);
  // const { width } = useWindowSize();

  const ongoingList = ongoinData.map((item, i) => (
    <div className="schedule_container" key={i} data-aos="fade-up">
      {/* <h2 className="title">{item.title}</h2> */}
      <div className="details_wrapper">
        <div className="time_wrapper">
          <h2 className="title">{item.date}</h2>
          <span className="time">{item.time}</span>
        </div>
        <div
          className={`logo_wrapper ${
            item.containerClass ? item.containerClass : ""
          }`}
        >
          {/* <div className="img_wrapper">
            <img
              src={item.team01Img}
              alt={item.team01Alt}
              className={`team_logo ${item.imgWidth1 ? item.imgWidth1 : ""}`}
              loading="lazy"
            />
            <img
              src={vsImg}
              alt="vs"
              className={`vs_img ${item.vsImg ? item.vsImg : ""}`}
              loading="lazy"
            />
            <img
              src={item.team02Img}
              alt={item.team02Alt}
              className={`team_logo ${item.imgWidth2 ? item.imgWidth2 : ""}`}
              loading="lazy"
            />
          </div> */}

          <div className="text_wrapper">
            <p className="winner_name">QUALIFER 1</p>
            <img
              src={vsImg}
              alt="vs"
              className={`vs_img ${item.vsImg ? item.vsImg : ""}`}
              loading="lazy"
            />
            <p className="winner_name">QUALIFER 2</p>
          </div>
        </div>
      </div>
    </div>
  ));

  const upcomingList = upcomingData.map((item, i) => (
    <div
      className="schedule_container"
      key={i}
      data-aos="fade-up"
      // data-aos-delay="500"
    >
      <div className="details_wrapper">
        <div className="time_wrapper">
          {/* <div className="background_white"></div> */}
          <h2 className="title">{item.date}</h2>
          <span className="time">{item.time}</span>
        </div>
        <div
          className={`logo_wrapper ${
            item.containerClass ? item.containerClass : ""
          }`}
        >
          <div
            className="img_wrapper"
            // ref={(el) => (imgBoxRefs.current[i] = el)}
          >
            <div className="scoredata_container">
              <img
                src={item.team01Img}
                alt={item.team01Alt}
                className={`team_logo ${item.imgWidth1 ? item.imgWidth1 : ""}`}
                loading="lazy"
              />
              <div className="scoredata">
                <p className="scoreupdate">{item.score1}</p>
                <p className="over">{item.over1}</p>
              </div>
            </div>
            <div className="result_container">
              <img
                src={vsImg}
                alt="vs"
                className={`vs_img ${item.vsImg ? item.vsImg : ""}`}
                loading="lazy"
              />
              <div className="result_wrapper">
                <p className="result">{item.result}</p>
              </div>
            </div>
            <div
              className="scoredata_container"
              // style={{
              //   height:
              //     width < 576 && isHeight[i] ? `${isHeight[i]}px` : "100%",
              // }}
            >
              <img
                src={item.team02Img}
                alt={item.team02Alt}
                className={`team_logo ${item.imgWidth2 ? item.imgWidth2 : ""}`}
                loading="lazy"
              />
              <div className="scoredata">
                <p className="scoreupdate">{item.score2}</p>
                <p className="over">{item.over2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, []);

  // const calculateHeights = () => {
  //   const heightsArray = imgBoxRefs.current.map((content) => {
  //     return content ? content.getBoundingClientRect().height : 0;
  //   });
  //   setIsHeight(heightsArray);
  // };
  
  // useEffect(() => {
  //   setTimeout(() => {
  //     calculateHeights();
  //   }, 100);
  // }, [upcomingData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  
  return (
    <>
      <Header />
      <main className="fixture_main">
        <section className="fixture_sec1">
          <div className="banner_container" data-aos="fade">
            <img
              src={fixtureBanner}
              alt="fixture banner"
              className="banner_img"
              loading="lazy"
            />
            <div className="my_container">
              <div className="breadcrumb">
                <div className="custom_breadcrumb">
                  <Link to={homepageURL} className="blink_1">
                    <img
                      width={20}
                      height={18}
                      src={homeIcon}
                      alt="home"
                      className="home_icon"
                      loading="lazy"
                    />
                  </Link>{" "}
                  <Link className="home_url" to={homepageURL}>
                    {" > "} Fixtures
                  </Link>{" "}
                </div>
              </div>
            </div>
            <div className="banner_content_container">
              <h1 className="heading">FIXTURES</h1>
              {/* <img
              src={whiteHeadBottom}
              alt="white cloud"
              className="banner_head_bottom"
            /> */}
            </div>
          </div>
        </section>

        {/* <section className="fixture_sec2">
          <img
            src={yellowTexture}
            alt="yellow texture"
            className="yellow_back_img"
          />
          <div className="schedule_box_container">{ongoingList}</div>
          <div className="divider_wrapper">
            <img src={dividerBat} alt="bat" className="bat_img" />
          </div>
        </section> */}

        <section className="fixture_sec3">
          <img
            src={yellowTexture}
            alt="yellow texture"
            className="yellow_back_img"
          />
          <div className="schedule_box_container">{upcomingList}</div>
        </section>
      </main>
      <ContactUs />
      <WhatsApp />
      <Footer />
    </>
  );
};

export default Fixture;
