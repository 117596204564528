import "./score.scss";
import Header from "../../components/Header/Header";

const Score = () => {
  return (
    <>
      <Header />

      <div className="score_wrapper">
        {/* <iframe
          title="score"
          src="https://www.sportstiger.com/live-cricket-score/31492/10292/skv-vs-cs-t10-match-12-indian-street-premier-league-2024/scorecard/completed"
          frameborder="0"
          style={{ width: "100%", height: "100vh" }}
        ></iframe> */}
        <iframe
          title="score"
          src="https://ispl-t10.com/team-list/chennai%20singams"
          frameborder="0"
          style={{ width: "100%", height: "100vh" }}
        ></iframe>
      </div>
    </>
  );
};

export default Score;
