import { images } from "../images";

export const ongoinData = [
  {
    id: 1,
    title: "SEMI-FINAL",
    date: "15 FEBRUARY, 2025",
    time: "08:00 PM",
    team01Img: images.teamLogo01.image,
    team01Alt: images.teamLogo01.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "mumbai_logo",
    imgWidth2: "singams_logo",
  },
];

export const upcomingData = [
  {
    id: 1,
    date: "27 JANUARY, 2025",
    time: "05:30 PM",
    team01Img: images.teamLogo02.image,
    team01Alt: images.teamLogo02.alt,
    team02Img: images.teamLogo04.image,
    team02Alt: images.teamLogo04.alt,
    imgWidth1: "singams_logo",
    imgWidth2: "falcon_logo",
    // vsImg: "vs_img5",
    containerClass: "logo_wrapper1",
    score1: "68/8",
    over1: "10 overs",
    score2: "73/2",
    over2: "7 overs",
    result: "FRH won by 8 wickets",
  },
  {
    id: 2,
    date: "28 JANUARY, 2025",
    time: "08:00 PM",
    team01Img: images.teamLogo05.image,
    team01Alt: images.teamLogo05.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "banglore_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img2",
    containerClass: "logo_wrapper3",
    score1: "66/9",
    over1: "10 overs",
    score2: "125/4",
    over2: "10 overs",
    result: "CS won by 59 runs",
  },
  {
    id: 3,
    date: "30 JANUARY, 2025",
    time: "05:30 PM",
    team01Img: images.teamLogo02.image,
    team01Alt: images.teamLogo02.alt,
    team02Img: images.teamLogo06.image,
    team02Alt: images.teamLogo06.alt,
    imgWidth1: "singams_logo",
    imgWidth2: "srinagar_logo",
    vsImg: "vs_img3",
    containerClass: "logo_wrapper4",
    score1: "107/5",
    over1: "10 overs",
    score2: "89/4",
    over2: "10 overs",
    result: "CS won by 18 runs",
  },
  {
    id: 4,
    date: "31 JANUARY, 2025",
    time: "08:00 PM",
    team01Img: images.teamLogo03.image,
    team01Alt: images.teamLogo03.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "tigers_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img5",
    containerClass: "logo_wrapper6",
    score1: "106/5",
    over1: "10 overs",
    score2: "105/5",
    over2: "10 overs",
    result: "TOK won by 5 wickets",
  },
  {
    id: 5,
    date: "01 FEBRUARY, 2025",
    time: "08:00 PM",
    team01Img: images.teamLogo01.image,
    team01Alt: images.teamLogo01.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "mumbai_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img6",
    containerClass: "logo_wrapper5",
    score1: "118/6",
    over1: "10 overs",
    score2: "103/5",
    over2: "10 overs",
    result: "MM won by 15 runs",
  },
  {
    id: 6,
    date: "04 FEBRUARY, 2025",
    time: "05:30 PM",
    team01Img: images.teamLogo02.image,
    team01Alt: images.teamLogo02.alt,
    team02Img: images.teamLogo04.image,
    team02Alt: images.teamLogo04.alt,
    imgWidth1: "singams_logo",
    imgWidth2: "falcon_logo",
    // vsImg: "vs_img",
    containerClass: "logo_wrapper1",
    score1: "67/3",
    over1: "7.1 overs",
    score2: "64/9",
    over2: "10 overs",
    result: "CS won by 7 wickets",
  },
  {
    id: 7,
    date: "05 FEBRUARY, 2025",
    time: "05:30 PM",
    team01Img: images.teamLogo05.image,
    team01Alt: images.teamLogo05.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "banglore_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img2",
    containerClass: "logo_wrapper3",
    score1: "89/1",
    over1: "7.2 overs",
    score2: "87/5",
    over2: "10 overs",
    result: "BS won by 9 wickets",
  },
  {
    id: 8,
    date: "06 FEBRUARY, 2025",
    time: "08:00 PM",
    team01Img: images.teamLogo02.image,
    team01Alt: images.teamLogo02.alt,
    team02Img: images.teamLogo06.image,
    team02Alt: images.teamLogo06.alt,
    imgWidth1: "singams_logo",
    imgWidth2: "srinagar_logo",
    vsImg: "vs_img3",
    containerClass: "logo_wrapper4",
    score1: "108/6",
    over1: "10 overs",
    score2: "112/3",
    over2: "9.2 overs",
    result: "SKV won by 7 wickets",
  },
  {
    id: 9,
    date: "08 FEBRUARY, 2025",
    time: "05:30 PM",
    team01Img: images.teamLogo03.image,
    team01Alt: images.teamLogo03.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "tigers_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img5",
    containerClass: "logo_wrapper6",
    score1: "68/1",
    over1: "6 overs",
    score2: "65/7",
    over2: "10 overs",
    result: "TOK won by 9 wickets",
  },
  {
    id: 10,
    date: "09 FEBRUARY, 2025",
    time: "05:30 PM",
    team01Img: images.teamLogo01.image,
    team01Alt: images.teamLogo01.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "mumbai_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img6",
    containerClass: "logo_wrapper5",
    score1: "122/3",
    over1: "10 overs",
    score2: "98/6",
    over2: "10 overs",
    result: "MM won by 24 runs",
  },
];
