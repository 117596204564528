import React, { useEffect, useState } from "react";
import "./stats.scss";
import { useWindowSize } from "react-use";
import Header from "../../components/Header/Header";
import ContactUs from "../../components/ContactUs/ContactUs";
import WhatsApp from "../../components/whatsApp/whatsApp";
import Footer from "../../components/Footer/Footer";
import {
  divBatStats,
  mbStatsBanner,
  mbStatsWhiteBack,
  statsBanner,
  statsWhiteBack,
  statsyellowBack,
} from "../../images";
import Banner from "../../components/Banner/Banner";
import {
  ballStatsData,
  ballStatsData2,
  batStatsData,
  batStatsData2,
} from "../../data/statsData";

const Stats = () => {
  const { width } = useWindowSize();
  const [isTab, setIsTab] = useState(0);
  const [isBallTab, setIsBallTab] = useState(0);
  const [mainTab, setMainTab] = useState(1);

  const batStatsTabList = batStatsData.map((tab, i) => (
    <>
      <button
        className={`tab_name ${isTab === i ? "active" : ""}`}
        key={i}
        onClick={() => setIsTab(i)}
      >
        {tab.tabName}
      </button>
    </>
  ));

  const batStatsTabList2 = batStatsData2.map((tab, i) => (
    <>
      <button
        className={`tab_name ${isTab === i ? "active" : ""}`}
        key={i}
        onClick={() => setIsTab(i)}
      >
        {tab.tabName}
      </button>
    </>
  ));

  const batStatsList = batStatsData[isTab]["battingStats"].map((stats, i) => (
    <div
      className={`col-lg-6 col-md-12 col-sm-6 stats_col ${
        isTab === i ? "active" : ""
      }`}
      key={i}
    >
      <div className="stats_wrapper">
        <img
          src={width > 767 ? statsWhiteBack : mbStatsWhiteBack}
          alt="background"
          className="stats_white_back"
        />
        <span className="numb">{stats.id}</span>
        <img src={stats.img} alt={stats.alt} className="player_img" />
        <h4 className="player_name">{stats.name}</h4>
        <div className="played_container">
          <div className="played_wrapper">
            <h6 className="played_numb">{stats.mplayed}</h6>
            <p className="played_title">MATCHES PLAYED</p>
          </div>
          <div className="played_divider"></div>
          <div className="played_wrapper">
            <h6 className="played_numb">{stats.tRuns}</h6>
            <p className="played_title">
              {batStatsData[isTab]["tabName"] === "Most Runs"
                ? "TOTAL RUNS"
                : "STRIKE RATE"}
            </p>
          </div>
        </div>
      </div>
    </div>
  ));

  const batStatsList2 = batStatsData2[isTab]["battingStats"].map((stats, i) => (
    <div
      className={`col-lg-6 col-md-12 col-sm-6 stats_col ${
        isTab === i ? "active" : ""
      }`}
      key={i}
    >
      <div className="stats_wrapper">
        <img
          src={width > 767 ? statsWhiteBack : mbStatsWhiteBack}
          alt="background"
          className="stats_white_back"
        />
        <span className="numb">{stats.id}</span>
        <img src={stats.img} alt={stats.alt} className="player_img" />
        <h4 className="player_name">{stats.name}</h4>
        <div className="played_container">
          <div className="played_wrapper">
            <h6 className="played_numb">{stats.mplayed}</h6>
            <p className="played_title">MATCHES PLAYED</p>
          </div>
          <div className="played_divider"></div>
          <div className="played_wrapper">
            <h6 className="played_numb">{stats.tRuns}</h6>
            <p className="played_title">
              {batStatsData[isTab]["tabName"] === "Most Runs"
                ? "TOTAL RUNS"
                : "STRIKE RATE"}
            </p>
          </div>
        </div>
      </div>
    </div>
  ));

  const ballStatsTabList = ballStatsData.map((tab, i) => (
    <>
      <button
        className={`tab_name ${isBallTab === i ? "active" : ""}`}
        key={i}
        onClick={() => setIsBallTab(i)}
      >
        {tab.tabName}
      </button>
    </>
  ));

  const ballStatsTabList2 = ballStatsData2.map((tab, i) => (
    <>
      <button
        className={`tab_name ${isBallTab === i ? "active" : ""}`}
        key={i}
        onClick={() => setIsBallTab(i)}
      >
        {tab.tabName}
      </button>
    </>
  ));

  const ballStatsList = ballStatsData[isBallTab]["bowlingStats"].map(
    (stats, i) => (
      <div
        className={`col-lg-6 col-md-12 col-sm-6 stats_col ${
          isBallTab === i ? "active" : ""
        }`}
        key={i}
      >
        <div className="stats_wrapper">
          <img
            src={width > 767 ? statsWhiteBack : mbStatsWhiteBack}
            alt="background"
            className="stats_white_back"
          />
          <span className="numb">{stats.id}</span>
          <img src={stats.img} alt={stats.alt} className="player_img" />
          <h4 className="player_name">{stats.name}</h4>
          <div className="played_container">
            <div className="played_wrapper">
              <h6 className="played_numb">{stats.mplayed}</h6>
              <p className="played_title">MATCHES PLAYED</p>
            </div>
            <div className="played_divider"></div>
            <div className="played_wrapper">
              <h6 className="played_numb">{stats.tWickets}</h6>
              <p
                className="played_title"
                dangerouslySetInnerHTML={{
                  __html:
                    ballStatsData[isBallTab]["tabName"] === "Wicket Takers"
                      ? "WICKETS"
                      : "BOWLING <br /> FIGURES",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    )
  );

  const ballStatsList2 = ballStatsData2[isBallTab]["bowlingStats"].map(
    (stats, i) => (
      <div
        className={`col-lg-6 col-md-12 col-sm-6 stats_col ${
          isBallTab === i ? "active" : ""
        }`}
        key={i}
      >
        <div className="stats_wrapper">
          <img
            src={width > 767 ? statsWhiteBack : mbStatsWhiteBack}
            alt="background"
            className="stats_white_back"
          />
          <span className="numb">{stats.id}</span>
          <img src={stats.img} alt={stats.alt} className="player_img" />
          <h4 className="player_name">{stats.name}</h4>
          <div className="played_container">
            <div className="played_wrapper">
              <h6 className="played_numb">{stats.mplayed}</h6>
              <p className="played_title">MATCHES PLAYED</p>
            </div>
            <div className="played_divider"></div>
            <div className="played_wrapper">
              <h6 className="played_numb">{stats.tWickets}</h6>
              <p
                className="played_title"
                dangerouslySetInnerHTML={{
                  __html:
                    ballStatsData[isBallTab]["tabName"] === "Wicket Takers"
                      ? "WICKETS"
                      : "BOWLING <br /> FIGURES",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    )
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <main className="stats_main">
        <section className="stats_sec1">
          <Banner
            linkText="Stats"
            bannerImg={width > 767 ? statsBanner : mbStatsBanner}
            disable
          />
        </section>

        <section className="stats_sec2">
          <img
            src={statsyellowBack}
            alt="yellow texture"
            className="yellow_back_img"
          />
          <div
            className={`my_container ${
              width > 1800 ? "my_container_stat" : ""
            }`}
          >
            <div className="main_tab_wrapper">
              <button
                className={`tab_name ${mainTab === 0 ? "active" : ""}`}
                onClick={() => setMainTab(0)}
              >
                Season 1
              </button>
              <button
                className={`tab_name ${mainTab === 1 ? "active" : ""}`}
                onClick={() => setMainTab(1)}
              >
                Season 2
              </button>
            </div>
            <div className="stats_box">
              <div className="heading_container" data-aos="fade-up">
                <h2 className="title">BATTING STATISTICS</h2>
                {mainTab === 0 && (
                  <div className="tab_wrapper">{batStatsTabList}</div>
                )}
                {mainTab === 1 && (
                  <div className="tab_wrapper">{batStatsTabList2}</div>
                )}
              </div>
              <div className="stats_container">
                {mainTab === 0 && (
                  <div className="row stats_row" data-aos="fade-up">
                    {batStatsList}
                  </div>
                )}
                {mainTab === 1 && (
                  <div className="row stats_row" data-aos="fade-up">
                    {batStatsList2}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="stats_sec3">
          <img
            src={statsyellowBack}
            alt="yellow texture"
            className="yellow_back_img"
          />
          <div className="divider_wrapper">
            <img src={divBatStats} alt="bat" className="bat_img" />
          </div>
          <div
            className={`my_container ${
              width > 1800 ? "my_container_stat" : ""
            }`}
          >
            <div className="stats_box">
              <div className="heading_container" data-aos="fade-up">
                <h2 className="title">BOWLING STATISTICS</h2>
                {mainTab === 0 && (
                  <div className="tab_wrapper">{ballStatsTabList}</div>
                )}
                {mainTab === 1 && (
                  <div className="tab_wrapper">{ballStatsTabList2}</div>
                )}
              </div>
              <div className="stats_container">
                {mainTab === 0 && (
                  <div className="row stats_row" data-aos="fade-up">
                    {ballStatsList}
                  </div>
                )}
                {mainTab === 1 && (
                  <div className="row stats_row" data-aos="fade-up">
                    {ballStatsList2}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>

      <ContactUs />
      <WhatsApp />
      <Footer />
    </>
  );
};

export default Stats;
